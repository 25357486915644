import {
  Box,
  Button,
  Grid2,
  Skeleton,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { ProductCard } from "../ProductCard";
import { Sidebar } from "../Sidebar";
import { ProductData } from "../../videomobileApi";
import {
  deserializeFilterUrlParams,
  isFutureDate,
} from "../../utility/utility";
import { useMobile } from "../../MobileProvider";
import { useSearchParams } from "react-router-dom";
import { setFilters, Status } from "../../store/slices/productSlice";
import { VirtualizedSearchBar } from "../Common";

const ITEMS_PER_PAGE = 20;

export const ProductPage = forwardRef<HTMLDivElement>((props, ref) => {
  const products = useAppSelector((state) => state.products);
  const brands = useAppSelector((state) => state.brands);
  const productTypes = useAppSelector((state) => state.productTypes);
  const filters = useAppSelector((state) => state.filters);
  const isMobile = useMobile();
  const dispatch = useAppDispatch();
  const [startingFiltersHaveBeenSet, setStartingFiltersHaveBeenSet] =
    useState<boolean>(false);

  //drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (val: boolean) => {
    setIsOpen(val);
  };
  const FilterDrawer = ({
    isOpen,
    toggleDrawer,
  }: {
    isOpen: boolean;
    toggleDrawer: (val: boolean) => void;
  }) => {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            height: "75%",
            p: 2,
          },
        }}
      >
        <VirtualizedSearchBar />
        <Sidebar />
      </SwipeableDrawer>
    );
  };
  //drawer finish
  const [visibleProductsCount, setVisibleProductsCount] =
    useState(ITEMS_PER_PAGE);

  const loadMoreProducts = () => {
    setVisibleProductsCount((prevCount) => prevCount + ITEMS_PER_PAGE); // Increase count by 12 or any number you prefer
  };
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      brands.dataState === Status.Fulfilled &&
      productTypes.dataState === Status.Fulfilled &&
      !startingFiltersHaveBeenSet
    ) {
      const {
        brandIds,
        minPrice,
        maxPrice,
        productTypeIds,
        comingSoon,
        onSale,
        newProducts,
      } = deserializeFilterUrlParams(searchParams);

      dispatch(
        setFilters({
          checkedBrands: brandIds,
          minPrice: minPrice,
          maxPrice: maxPrice,
          checkedProductTypes: productTypeIds,
          comingSoon: comingSoon,
          onSale: onSale,
          newProducts: newProducts,
        })
      );
      setStartingFiltersHaveBeenSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands.dataState, productTypes.dataState]);

  useEffect(() => {
    if (startingFiltersHaveBeenSet) {
      const params = new URLSearchParams();
      if (filters.brand.data.length) {
        const brandIds = filters.brand.data
          .filter((data) => data.isChecked)
          .map((data) => data.brand.id)
          .join(",");
        params.set("brands", brandIds);
      }

      if (filters.productType.data.length) {
        const productTypeIds = filters.productType.data
          .filter((data) => data.isChecked)
          .map((data) => data.productType.id)
          .join(",");
        params.set("productTypes", productTypeIds);
      }

      if (filters.price.data.minPrice) {
        params.set("minPrice", filters.price.data.minPrice.toString());
      }

      if (filters.price.data.maxPrice) {
        params.set("maxPrice", filters.price.data.maxPrice.toString());
      }

      if (filters.comingSoon.data) {
        params.set("comingSoon", "true");
      }

      if (filters.newProducts.data) {
        params.set("newProducts", "true");
      }

      if (filters.onSale.data) {
        params.set("onSale", "true");
      }

      setSearchParams(params);
    }
  }, [
    brands.dataState,
    filters,
    productTypes.dataState,
    setSearchParams,
    startingFiltersHaveBeenSet,
  ]);

  var checkedBrands: string[] = useMemo(() => {
    return filters.brand.data.reduce<string[]>((arr, data) => {
      if (data.isChecked) {
        arr.push(data.brand.id);
      }
      return arr;
    }, []);
  }, [filters.brand.data]);

  var checkedProductTypes: string[] = useMemo(() => {
    return filters.productType.data.reduce<string[]>((arr, data) => {
      if (data.isChecked) {
        arr.push(data.productType.id);
      }
      return arr;
    }, []);
  }, [filters.productType.data]);

  const filteredProducts = useMemo(() => {
    return products.list.filter((product: ProductData) => {
      if (filters.onSale.data && !isFutureDate(product.discountUntil)) {
        return false;
      }

      if (filters.comingSoon.data && !isFutureDate(product.comingSoonUntil)) {
        return false;
      }

      if (filters.newProducts.data && !isFutureDate(product.newProductUntil)) {
        return false;
      }

      if (
        checkedBrands.length &&
        !checkedBrands.includes(product.brandId ?? "")
      ) {
        return false;
      }

      const price =
        isFutureDate(product.discountUntil) && product.discountPrice
          ? product.discountPrice
          : product.price;

      if (
        price > filters.price.data.maxPrice ||
        price < filters.price.data.minPrice
      ) {
        return false;
      }

      if (
        checkedProductTypes.length &&
        !product.types?.some((type) => checkedProductTypes.includes(type))
      ) {
        return false;
      }

      return true;
    });
  }, [
    checkedBrands,
    checkedProductTypes,
    filters.comingSoon.data,
    filters.newProducts.data,
    filters.onSale.data,
    filters.price.data.maxPrice,
    filters.price.data.minPrice,
    products.list,
  ]);

  const productGrid = () => {
    return (
      <Grid2
        container
        flexShrink={1}
        flexGrow={0}
        spacing={1}
        sx={{
          scrollbarGutter: "stable",
        }}
      >
        {products.dataState !== Status.Fulfilled ? (
          Array.from(new Array(ITEMS_PER_PAGE)).map((_, index) => {
            return (
              <Grid2 key={index} size={{ xs: 6, sm: 6, md: 3, lg: 2 }}>
                <Box sx={{ padding: 1 }}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                </Box>
              </Grid2>
            );
          })
        ) : (
          <></>
        )}
        {filteredProducts.slice(0, visibleProductsCount).map((product) => {
          return (
            <Grid2 key={product.id} size={{ xs: 6, sm: 6, md: 3, lg: 2 }}>
              <ProductCard product={product} />
            </Grid2>
          );
        })}
      </Grid2>
    );
  };

  return (
    <Stack
      ref={ref}
      direction={"row"}
      spacing={0}
      sx={{ minHeight: "100vh", width: "100%" }}
    >
      {!isMobile && (
        <Box
          sx={{
            position: "sticky",
            top: "60px",
            height: "100vh",
          }}
        >
          <Box sx={{ overflow: "auto", maxHeight: "100vh", width: 250 }}>
            <Sidebar />
          </Box>
        </Box>
      )}
      <Box flexGrow={1} p={2} sx={{ flexGrow: 1, p: 2, width: "100%" }}>
        {productGrid()}
        {visibleProductsCount < filteredProducts.length && (
          <Button sx={{ width: "100%" }} onClick={() => loadMoreProducts()}>
            Load More
          </Button>
        )}
      </Box>
      {isMobile && (
        <>
          <Box
            sx={{
              position: "fixed",
              bottom: 16, // Distance from the bottom of the viewport
              left: "50%", // Center horizontally
              transform: "translateX(-50%)", // Center adjustment
              zIndex: 1000, // Ensure it appears above other content
            }}
          >
            <Button variant="contained" onClick={() => toggleDrawer(true)}>
              Filter Products
            </Button>
          </Box>
          <FilterDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        </>
      )}
    </Stack>
  );
});
