import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Homepage } from "./components";
import { NavBar } from "./components";
import { routesList } from "./constants";
import { Box, Container } from "@mui/material";
import { Footer } from "./components/Footer";
import { NewSingleProductPage } from "./components/SingleProductPage";

function AppContent() {
  return (
    <Container
      maxWidth="xl"
      sx={{
        background: "#fff",
        top: "0px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {/* {isMobile && <Drawer open={true} anchor="right"></Drawer>} */}
      <NavBar />
      <Box flexGrow={1}>
        <Routes>
          <Route index element={<Homepage />} />
          <Route
            path={routesList.singleProduct}
            element={<NewSingleProductPage />}
          />
          <Route path="*" element={<Homepage />} />
        </Routes>
      </Box>
      <Footer />
      {/* <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels value={0}>
          <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        </BottomNavigation>
      </Paper> */}
    </Container>
  );
}

export default AppContent;
