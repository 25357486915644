import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ConfigurationParameters,
  Configuration,
  WebApi,
} from "../../videomobileApi";

const configParameters: ConfigurationParameters = {
  // basePath: "http://192.168.178.43:8080",
  basePath: "https://api.testvideomobile.org",
};

const configuration = new Configuration(configParameters);

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (_, { rejectWithValue }) => {
    const productApi = new WebApi(configuration);
    try {
      const response = await productApi.getAllProducts();
      return response.data;
    } catch (error) {
      return rejectWithValue("Error fetching products");
    }
  }
);

export const getBrands = createAsyncThunk(
  "brands/getBrands",
  async (_, { rejectWithValue }) => {
    const brandApi = new WebApi(configuration);
    try {
      const response = await brandApi.getBrandData();
      return response.data;
    } catch (error) {
      return rejectWithValue("Error fetching brands");
    }
  }
);

export const getSlideshows = createAsyncThunk(
  "slideshows/getSlideshows",
  async (_, { rejectWithValue }) => {
    const slideshowApi = new WebApi(configuration);

    try {
      const response = await slideshowApi.getSlideshowData();
      return response.data;
    } catch (error) {
      return rejectWithValue("Error fetching slideshows");
    }
  }
);

export const getProductTypes = createAsyncThunk(
  "slideshows/getProductTypes",
  async (_, { rejectWithValue }) => {
    const productTypesApi = new WebApi(configuration);

    try {
      const response = await productTypesApi.getProductTypes();
      return response.data;
    } catch (error) {
      return rejectWithValue("Error fetching productTypes");
    }
  }
);
